import request from '@/utils/request'
import constant from '@/constant'

function pageList(formData){
  return request.post(constant.serverUrl + "/interest/course/pageList", formData);
}

function create(){
  return request.get(constant.serverUrl + "/interest/course/create");
}

function edit(id){
  return request.get(constant.serverUrl + "/interest/course/edit/" + id);
}

function add(formModel){
  return request.post(constant.serverUrl + "/interest/course/add", formModel,{
    headers: {
      "Content-Type": "application/json"
    }
  });
}

function update(formModel){  
  return request.post(constant.serverUrl + "/interest/course/update", formModel,{
    headers: {
      "Content-Type": "application/json"
    }
  });
}

function remove(id){
  return request.post(constant.serverUrl + "/interest/course/delete/" + id);
}

function batchRemove(idList){
  return request.post(constant.serverUrl + "/interest/course/batchDelete",idList,{
    headers: {
      "Content-Type": "application/json"
    }
  });
}

function queryGradeList(courseId,schoolId){
  return request.get(constant.serverUrl + `/interest/course/queryGradeList?courseId=${courseId}&schoolId=${schoolId}`);
}

function queryTermList(schoolId){
  return request.get(constant.serverUrl + `/interest/course/queryTermList?schoolId=${schoolId}`);
}

function queryCourseList(schoolId,termId){
  return request.get(constant.serverUrl + `/interest/course/queryCourseList?schoolId=${schoolId}&termId=${termId}`);
}

function updateActive(id,active){
  var formData = new FormData();
  formData.append("id", id);
  formData.append("active", active);

  return request.post(constant.serverUrl + "/interest/course/updateActive", formData);
}

export default {
  pageList,create,edit,add,update,remove,batchRemove,queryGradeList,queryTermList,queryCourseList,updateActive
}