<template>
  <div>
    <el-breadcrumb separator=">">
      <el-breadcrumb-item>{{ course.schoolName }}</el-breadcrumb-item>
      <el-breadcrumb-item>{{ course.termName }}</el-breadcrumb-item>
      <el-breadcrumb-item>{{ course.courseName }}</el-breadcrumb-item>
    </el-breadcrumb>
    <el-divider></el-divider>
    <!--
      要resetFields起作用，必须配置:model和prop
    -->
    <el-form
      ref="queryForm"
      :model="queryModel"
      inline
      class="demo-form-inline"
    >
      <el-form-item label="年级" prop="gradeId">
        <el-select
          v-model="queryModel.gradeId"
          clearable
          filterable
          placeholder="请选择"
          style="width: 200px"
          size="mini"
        >
          <el-option
            v-for="result in gradeList"
            :key="result.id"
            :label="result.name"
            :value="result.id"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="班级" prop="classId">
        <el-select
          v-model="queryModel.classId"
          clearable
          filterable
          placeholder="请选择"
          style="width: 200px"
          size="mini"
        >
          <el-option
            v-for="result in classList"
            :key="result.id"
            :label="result.name"
            :value="result.id"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="社团班级" prop="interestClassId">
        <el-select
          v-model="queryModel.interestClassId"
          clearable
          filterable
          placeholder="请选择"
          style="width: 200px"
          size="mini"
        >
          <el-option
            v-for="result in interestClassList"
            :key="result.id"
            :label="result.className"
            :value="result.id"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="学生姓名" prop="studentName">
        <el-input v-model="queryModel.studentName" size="mini"></el-input>
      </el-form-item>
      <el-form-item label="状态" prop="status">
        <el-select
          v-model="queryModel.status"
          clearable
          filterable
          placeholder="请选择"
          style="width: 200px"
          size="mini"
        >
          <el-option label="全部" value=""></el-option>
          <el-option label="已报名" value="1"></el-option>
          <el-option label="已分班" value="2"></el-option>
          <el-option label="已取消" value="3"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="推送状态" prop="pushStatus">
        <el-select
          v-model="queryModel.pushStatus"
          clearable
          filterable
          placeholder="请选择"
          style="width: 200px"
          size="mini"
        >
          <el-option label="全部" value=""></el-option>
          <el-option label="未推送" value="0"></el-option>
          <el-option label="已推送" value="1"></el-option>
          <el-option label="推送失败" value="2"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="支付状态" prop="payStatus">
        <el-select
          v-model="queryModel.payStatus"
          clearable
          filterable
          placeholder="请选择"
          style="width: 200px"
          size="mini"
        >
          <el-option label="全部" value=""></el-option>
          <el-option label="未支付" value="10"></el-option>
          <el-option label="已支付" value="20"></el-option>
          <el-option label="已关闭" value="30"></el-option>
          <el-option label="已退款" value="40"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="支付时间">
          <el-date-picker
          v-model="queryModel.payTime"
          type="daterange"
          value-format="yyyy-MM-dd"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
        ></el-date-picker>
        </el-form-item>
      <el-form-item>
        <el-button
          type="primary"
          size="mini"
          icon="ios-search"
          @click="changePage(1)"
          :loading="loading"
          >查询</el-button
        >&nbsp;
        <el-button
          type="info"
          size="mini"
          style="margin-left: 8px"
          @click="handleReset('queryForm')"
          >重置</el-button
        >
      </el-form-item>
    </el-form>
    <el-divider></el-divider>
    <el-row class="button-group">
      <el-button
        type="primary"
        size="small"
        plain
        icon="el-icon-circle-plus"
        @click="handleImport"
        >导入名单</el-button
      >
      <el-button
        type="primary"
        size="small"
        plain
        icon="el-icon-circle-plus"
        :disabled="multipleSelection.length == 0"
        @click="handleBatchEdit"
        >批量分班</el-button
      >
      <el-button
        type="primary"
        size="small"
        plain
        icon="el-icon-download"
        @click="exportXls()"
        >导出</el-button
      >
      <el-button
        type="primary"
        size="small"
        plain
        icon="el-icon-upload2"
        @click="batchPush = true"
        >推送订单</el-button
      >
    </el-row>
    <el-table
      ref="formTable"
      :data="tableData"
      style="min-height: 400px"
      v-loading="loading"
      stripe
      :height="tableHeight"
      @selection-change="handleSelectionChange"
      @sort-change="sortChange"
    >
      <el-table-column type="selection" width="55"></el-table-column>
      <el-table-column type="index" label="序号" :index="indexMethod" width="50"></el-table-column>
      <el-table-column
        prop="studentName"
        label="学生姓名"
        width="80"
      ></el-table-column>
      <el-table-column
        prop="phone"
        label="联系电话"
        width="120"
      ></el-table-column>
      <el-table-column
        prop="idCard"
        label="身份证号"
        width="180"
      ></el-table-column>
      <el-table-column
        prop="imageUrl"
        label="上传照片"
        width="160"
      >
      <template slot-scope="{ row }">
          <template v-if="row.imageUrl!=null && row.imageUrl.length>0">
            <a :href="item" v-for="(item,index) in row.imageUrl.split(',')"
              target="_blank" :key="index">
              <el-avatar
                :size="64"
                shape="square"
                :src="item + '?x-oss-process=image/resize,m_fill,w_64,h_64'"
                :key="index"
              ></el-avatar>
            </a>
          </template>
        </template>
      </el-table-column>
      <el-table-column
        prop="className"
        label="班级名称"
        width="180"
      ></el-table-column>
      <el-table-column
        prop="interestCourseName"
        label="兴趣社团"
        width="180"
      ></el-table-column>
      <el-table-column
        prop="interestClassName"
        label="兴趣班名称"
        width="180"
      ></el-table-column>
      <el-table-column
        prop="statusN"
        label="状态"
        width="120"
      ></el-table-column>
      <el-table-column prop="pushStatus" label="推送状态" width="220" show-overflow-tooltip	
        ><template slot-scope="{ row }">
          <span v-if="row.pushStatus == 0 || row.pushStatus == null">未推送</span> 
          <span v-else-if="row.pushStatus == 1">已推送</span> 
          <span v-else-if="row.pushStatus == 2">推送失败</span>
          <span v-else>{{row.pushStatus}}</span>  </template
      ></el-table-column>
      <el-table-column
        prop="payStatus"
        label="订单状态"
        width="120"
      ><template slot-scope="{ row }">
          <span v-if="row.payStatus == '' || row.payStatus == null">未推送</span> 
          <span v-if="row.payStatus == 10">未支付</span> 
          <span v-if="row.payStatus == 20">已支付</span> 
          <span v-if="row.payStatus == 30">已关闭</span> 
          <span v-if="row.payStatus == 40">已退款</span> </template
      ></el-table-column>
       <el-table-column
        prop="payTime"
        label="支付时间" 
        sortable="custom"
        width="180"
      ></el-table-column>
      <el-table-column
        prop="createTime"
        label="创建时间" 
        sort-by="a.create_time"
        sortable="custom"
        width="180"
      ></el-table-column>
      <el-table-column prop="updateTime" label="更新时间" width="180"></el-table-column>
      <el-table-column label="操作" fixed="right" width="200">
        <template slot-scope="{ row }">
          <el-button size="mini" type="success" @click="handleEdit(row)"
            >分班</el-button>
          <el-button size="mini" type="warning" @click="handleDelete(row)"
            >删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      :current-page.sync="pageIndex"
      :total="totalElements"
      :page-sizes="pageSizeList"
      @current-change="changePage"
      @size-change="pageSizeChange"
      layout="total, sizes, prev, pager, next, jumper"
    ></el-pagination>
    <interestRegister-detail
      v-if="showModal"
      :businessKey="businessKey"
      :title="modalTitle"
      @close="onDetailModalClose"
    ></interestRegister-detail>
    <el-dialog
      :visible.sync="showSelectModal"
      title="兴趣班选择"
      :modal-append-to-body="true"
      :append-to-body="true"
      style="text-align: left"
      :width="'400px'"
    >
      <el-select
        v-model="selectedClassId"
        clearable
        filterable
        placeholder="请选择"
        style="width: 300px"
      >
        <el-option
          v-for="result in interestClassList"
          :key="result.id"
          :label="result.className"
          :value="result.id"
        >
        </el-option>
      </el-select>
      <span slot="footer" class="dialog-footer">
        <el-button @click="showSelectModal = false">取 消</el-button>
        <el-button
          type="primary"
          @click="handleBatchUpdate"
          :loading="submitting"
          >确 定</el-button
        >
      </span>
    </el-dialog>
    <el-dialog
      title="导入名单"
      :visible.sync="batchImportVisible"
      :modal-append-to-body="false"
      style="text-align: left"
      :close-on-click-modal="false"
    >
      <el-form label-width="150px">
        <el-form-item label="模板下载">
          <el-link
            href="http://rccs.oss-cn-hangzhou.aliyuncs.com/smart/personInfo/2020/9/interest_template.xls"
            type="primary"
            target="_blank"
            >下载模板</el-link
          >
        </el-form-item>
        <el-form-item label="上传文件">
          <el-upload
            class="upload-demo"
            accept=".xls"
            :action="importXlsUrl"
            :headers="headers"
            name="uploadFile"
            :before-upload="beforeUpload"
            :on-success="handleBatchImportSuccess"
            :file-list="batchImportFileList"
            :data="uploadData"
          >
            <el-button size="small" type="primary" :loading="submitting"
              >点击上传</el-button
            >
            <div slot="tip" class="el-upload__tip">
              只能上传xls文件，且不超过500kb
            </div>
          </el-upload>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="batchImportVisible = false"
          >关 闭</el-button
        >
      </div>
    </el-dialog>
    <el-dialog
      title="订单推送"
      :visible.sync="batchPush"
      :modal-append-to-body="false"
      style="text-align: left"
      :close-on-click-modal="false"
    >
      <el-form label-width="150px">
        <el-form-item label="选择商品">
          <el-select
            v-model="pushGoodsId"
            clearable
            filterable
            placeholder="请选择"
            style="width: 200px"
          >
            <el-option
              v-for="result in goodsList"
              :key="result.id"
              :label="result.name"
              :value="result.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="消息推送费用名称">
          <el-input v-model="pushName" style="width: 200px"></el-input>
        </el-form-item>
        <el-form-item label="消息推送备注">
          <el-input v-model="pushRemark" style="width: 60%"></el-input>
        </el-form-item>
        <el-form-item label="是否定时发送">
          <el-radio v-model="isTiming" :label="false">直接发送</el-radio>
          <el-radio v-model="isTiming" :label="true">定时发送</el-radio>
        </el-form-item>
        <el-form-item label="定时发送时间" v-if="isTiming">
          <el-date-picker
            v-model="sendDate"
            type="datetime"
            value-format="yyyy-MM-dd HH:mm:ss"
            placeholder="选择日期时间">
          </el-date-picker>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="pushOrder">推送</el-button>
        <el-button @click="batchPush = false">关 闭</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import Constant from "@/constant";
import InterestRegisterDetail from "./interestRegister-detail";
import interestCourseApi from "@/api/interest/interestCourse";
import interestClassApi from "@/api/interest/interestClass";
import goodsInfoApi from "@/api/base/goodsInfo";
import interestRegisterApi from "@/api/interest/interestRegister";
import companyInfoApi from "@/api/base/companyInfo";
import pageUtil from "@/utils/page";
import { getToken } from "@/utils/auth"; // get token from cookie
import NProgress from "nprogress"; // progress bar
import "nprogress/nprogress.css"; // progress bar style

export default {
  name: "interestRegisterList",
  data() {
    var self = this;

    return {
      queryModel: {
        gradeId: "",
        classId: "",
        interestCourseId: "",
        interestClassId: "",
        studentName: "",
        status: "1",
        pushStatus: "",
        payStatus: "",
        payTime: ""
      },
      loading: false,
      tableData: [],
      tableHeight: 400,
      pageIndex: 1,
      pageSize: 50,
      totalPages: 0,
      totalElements: 0,
      field: "",
      direction: "",
      pageSizeList: [50, 100, 300],
      multipleSelection: [],
      showModal: false,
      modalTitle: "",
      businessKey: "",
      interestClassList: [],
      selectedClassId: "",
      gradeList: [],
      classList: [],
      course: {},
      showSelectModal: false,
      submitting: false,
      batchImportVisible: false,
      batchImportFileList: [],
      importXlsUrl: Constant.serverUrl + "/interest/register/importXls",
      headers: {
        Authorization: getToken(),
      },
      uploadData: {
        interestCourseId: "",
      },
      batchPush: false,
      goodsList: [],
      pushGoodsId: "",
      pushName: "",
      pushRemark: "",
      isTiming:false,
      sendDate:""
    };
  },
  methods: {
    indexMethod(index) {
      return (this.pageIndex - 1) * this.pageSize + (index + 1);
    },
    exportXls() {
      this.changePage(1, true);
    },
    changePage(pageIndex, exportFlag) {
      var self = this;
      self.loading = true;

      self.pageIndex = pageIndex;
      var formData = new FormData();

      formData.append("pageIndex", self.pageIndex);

      if (exportFlag != null) {
        formData.append("exportFlag", 1);
        formData.append("pageSize", 10000);
      } else {
        formData.append("pageSize", self.pageSize);
      }

      if (self.queryModel.gradeId != null) {
        formData.append("gradeId", self.queryModel.gradeId);
      }

      if (self.queryModel.classId != null) {
        formData.append("classId", self.queryModel.classId);
      }

      if (self.queryModel.interestCourseId != null) {
        formData.append("interestCourseId", self.queryModel.interestCourseId);
      }

      if (self.queryModel.interestClassId != null) {
        formData.append("interestClassId", self.queryModel.interestClassId);
      }

      if (self.queryModel.studentName != null) {
        formData.append("studentName", self.queryModel.studentName);
      }

      if (self.queryModel.status != null) {
        formData.append("status", self.queryModel.status);
      }

      if (self.queryModel.pushStatus != null) {
        formData.append("pushStatus", self.queryModel.pushStatus);
      }

      if (self.queryModel.payStatus != null) {
        formData.append("payStatus", self.queryModel.payStatus);
      }

      if(self.queryModel.payTime[0] != null){
        formData.append("payBeginTime", self.queryModel.payTime[0]);
      }

      if(self.queryModel.payTime[1] != null){
        formData.append("payEndTime", self.queryModel.payTime[1]);
      }

      if (this.field != null) {
        formData.append("field", this.field);
      }

      if (this.direction != null) {
        formData.append("direction", this.direction);
      }

      interestRegisterApi
        .pageList(formData)
        .then(function (response) {
          self.loading = false;

          var jsonData = response.data;

          if (jsonData.result) {
            if (exportFlag) {
              //导出
              self.$message({
                showClose: true,
                type: "success",
                message: `报表已生成，<a href="${jsonData.data}">请点击链接下载</a>`,
                dangerouslyUseHTMLString: true,
                duration: 30000,
              });
            } else {
              //分页查看
              var page = jsonData.data;

              self.tableData = page.data;
              self.totalPages = page.totalPages;
              self.totalElements = page.recordsTotal;
            }
          } else {
            self.$message.warning(jsonData.message + "");
          }
        })
        .catch((error) => {
          self.loading = false;
        });
    },
    pageSizeChange(pageSize) {
      this.pageSize = pageSize;

      this.$nextTick(() => {
        this.changePage(this.pageIndex);
      });
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    handleReset(name) {
      this.$refs[name].resetFields();
    },
    handleImport() {
      // this.$message.warning("暂未实现!");
      this.batchImportVisible = true;
    },
    handleEdit(record) {
      this.modalTitle = "分班";
      this.businessKey = record.id;
      this.showModal = true;
    },
    handleBatchEdit() {
      this.showSelectModal = true;
    },
    handleBatchUpdate() {
      var self = this;

      var idList = this.multipleSelection.map((record) => {
        return record.id;
      });

      var formData = new FormData();
      formData.append("id", idList.join(","));
      formData.append("interestCourseId", this.queryModel.interestCourseId);
      formData.append("interestClassId", this.selectedClassId);

      interestRegisterApi.update(formData).then((response) => {
        var jsonData = response.data;

        if (jsonData.result) {
          this.changePage(this.pageIndex);
        }
      });

      this.showSelectModal = false;
    },
    onDetailModalClose(refreshed) {
      //保存成功后回调
      this.showModal = false;

      if (refreshed) {
        this.changePage(this.pageIndex);
      }
    },
    loadGradeAndClassList() {
      //班级
      companyInfoApi
        .list({ companyId: this.course.schoolId, type: "5" })
        .then((response) => {
          var jsonData = response.data;
          this.gradeList = jsonData.data;
        });

      //年级
      companyInfoApi
        .list({ companyId: this.course.schoolId, type: "3" })
        .then((response) => {
          var jsonData = response.data;
          this.classList = jsonData.data;
        });
    },
    beforeUpload(file, fileList) {
      this.submitting = true;
      return true;
    },
    handleBatchImportSuccess(response, file, fileList) {
      var self = this;
      self.submitting = false;

      if (response.result) {
        self.$message.success(response.message);
        this.batchImportFileList = [];
        this.changePage(1);
        this.batchImportVisible = false;
      } else {
        //this.$message.error(response.message);
        this.batchImportFileList = [];
        this.changePage(1);

        if (response.data != null) {
          //下载有错误信息提示的报表
          //window.open(response.data);
          self.$message({
            showClose: true,
            dangerouslyUseHTMLString: true,
            message:
              response.message +
              `,<a href="${response.data}" target="_blank">点击下载未导入的数据报表</a>&nbsp;`,
            duration: 30000,
          });
        }
      }
    },
    handleDelete(record) {
      var self = this;

      self
        .$confirm("是否确认删除?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
        .then(() => {
          interestRegisterApi.remove(record.id).then(function (response) {
            var jsonData = response.data;

            if (jsonData.result) {
              // var index = self.tableData.indexOf(record);
              // self.tableData.splice(index, 1);
              self.changePage(self.pageIndex);

              self.$message({
                type: "success",
                message: "删除成功!",
              });
            }
          });
        });
    },
    pushOrder() {
      var self = this;

      self.loading = true;
      var idList = this.multipleSelection.map((record) => {
        return record.id;
      });

      var formData = new FormData();
      formData.append("name", self.pushName);
      formData.append("ids", idList.join(","));
      formData.append("goodsId", self.pushGoodsId);
      formData.append("companyId", self.course.schoolId);
      formData.append("orderRemark", self.pushRemark);
      formData.append("isTiming", self.isTiming);
      formData.append("sendDate", self.sendDate);

      interestRegisterApi.orderBatchGenerate(formData).then((response) => {
        self.loading = false;
        var jsonData = response.data;
        if (jsonData.result) {
          self.pushGoodsId = "";
          self.pushName = "";
          self.pushRemark = "";
          self.isTiming = false;
          self.sendDate = "";
          self.changePage(this.pageIndex);
        }else{
          self.$message({
                type: "error",
                message: jsonData.message,
              });
        }
      });

      this.batchPush = false;
    },
    sortChange(data) {
      this.field = data.column.sortBy;
      this.direction = data.order == "ascending" ? "asc" : "desc";

      this.changePage(this.pageIndex);
    }
  },
  created: function () {
    console.log("created");

    this.queryModel.interestCourseId = this.$route.query.interestCourseId;
    this.queryModel.interestClassId = this.$route.query.interestClassId;

    this.uploadData.interestCourseId = this.queryModel.interestCourseId;

    //读取兴趣社团下班级
    interestClassApi
      .queryClassList(this.queryModel.interestCourseId)
      .then((response) => {
        var jsonData = response.data;
        this.loading = false;

        if (jsonData.result) {
          this.interestClassList = jsonData.data;
        }
      });

    goodsInfoApi.typeList({ type: null }).then((response) => {
      var jsonData = response.data;
      this.goodsList = jsonData.data;
    });
  },
  mounted: function () {
    console.log("mounted");
    var self = this;

    setTimeout(()=>{
      self.tableHeight = pageUtil.autoAdjustHeight(self.$refs.formTable.$el);
    },1000);

    this.loading = true;

    interestCourseApi
      .edit(this.queryModel.interestCourseId)
      .then((response) => {
        var jsonData = response.data;
        this.loading = false;

        if (jsonData.result) {
          this.course = jsonData.data.course;

          this.loadGradeAndClassList();

          this.changePage(1);
        }
      });
  },
  beforeRouteUpdate(to, from, next) {
    // 在当前路由改变，但是该组件被复用时调用
    // 举例来说，对于一个带有动态参数的路径 /foo/:id，在 /foo/1 和 /foo/2 之间跳转的时候，
    // 由于会渲染同样的 Foo 组件，因此组件实例会被复用。而这个钩子就会在这个情况下被调用。
    // 可以访问组件实例 `this`

    console.log("beforeRouteUpdate");
  },
  components: {
    "interestRegister-detail": InterestRegisterDetail,
  },
};
</script>
<style lang="scss" scoped>
.el-breadcrumb {
  margin: 10px;
  line-height: 20px;
}

.el-divider {
  margin: 5px 0;
}

.demo-form-inline {
  margin-left: 10px;
  text-align: left;
}

.button-group {
  margin-left: 10px;
  text-align: left;
}
</style>