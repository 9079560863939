import request from '@/utils/request'
import constant from '@/constant'

function pageList(formData) {
  return request.post(constant.serverUrl + "/interest/register/pageList", formData);
}

function edit(id) {
  return request.get(constant.serverUrl + "/interest/register/edit/" + id);
}


function update(formData) {
  return request.post(constant.serverUrl + "/interest/register/update", formData);
}

function remove(id){
  return request.post(constant.serverUrl + "/interest/register/delete/" + id);
}

function listByClass(formData){
  return request.post(constant.serverUrl + "/interest/register/listByClass",formData);
}

//批量生成订单
function orderBatchGenerate(formData) {
  return request.post(constant.serverUrl + "/interest/register/orderBatchGenerate",formData);
}

export default {
  pageList, edit, update, remove,listByClass,orderBatchGenerate
}