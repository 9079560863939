
<style scoped>
.user-panel {
  margin: 10px auto;
}
</style>
<template>
  <el-dialog
    :visible.sync="showDialog"
    :title="title"
    :modal-append-to-body="false"
    style="text-align:left;"
    @close="closeDialog"
  >
    <div class="user-panel" v-loading="loading">
      <el-form ref="form" :model="formModel" :rules="ruleValidate" :label-width="'100px'">
        <el-form-item label="班级" prop="className">
          <el-input v-model="formModel.className" disabled style="width:300px"></el-input>
        </el-form-item>
        <el-form-item label="学生姓名" prop="studentName">
          <el-input v-model="formModel.studentName" disabled style="width:300px"></el-input>
        </el-form-item>
        <el-form-item label="兴趣社团" prop="interestCourseId">
          <el-select
            v-model="formModel.interestCourseId"
            clearable
            filterable
            placeholder="请选择"
            style="width:300px"
          >
            <el-option
              v-for="result in interestCourseList"
              :key="result.id"
              :label="result.courseName"
              :value="result.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="社团班级" prop="interestClassId">
          <el-select
            v-model="formModel.interestClassId"
            clearable
            filterable
            placeholder="请选择"
            style="width:300px"
          >
            <el-option
              v-for="result in interestClassList"
              :key="result.id"
              :label="result.className"
              :value="result.id"
            ></el-option>
          </el-select>
        </el-form-item>
      </el-form>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="closeDialog">取 消</el-button>
      <el-button type="primary" @click="handleSubmit" :loading="submitting">确 定</el-button>
    </span>
  </el-dialog>
</template>
<script>
import Constant from "@/constant";
import interestRegisterApi from "@/api/interest/interestRegister";
import interestCourseApi from "@/api/interest/interestCourse";
import interestClassApi from "@/api/interest/interestClass";

export default {
  props: ["businessKey", "title"],
  data() {
    return {
      ruleValidate: {
        interestCourseId: [
          { required: true, message: "兴趣社团不能为空", trigger: "blur" },
        ],
        interestClassId: [
          { required: true, message: "社团班级不能为空", trigger: "blur" },
        ],
      },
      showDialog: true,
      loading: false,
      submitting: false,
      formModel: {},
      interestCourseList: [],
      interestClassList: []
    };
  },
  methods: {
    closeDialog() {
      this.$emit("close", false);
    },
    handleSubmit() {
      var self = this;

      this.$refs["form"].validate((valid) => {
        if (valid) {
          var formData = new FormData();
          formData.append("id", this.formModel.id);
          formData.append("interestCourseId", this.formModel.interestCourseId);
          formData.append("interestClassId", this.formModel.interestClassId);

          interestRegisterApi.update(formData).then(function (response) {
            var jsonData = response.data;

            if (jsonData.result) {
              self.$message({
                message: "保存成功!",
                type: "success",
              });

              self.$emit("close", true);
            } else {
              self.$message({
                message: jsonData.message + "",
                type: "warning",
              });

              self.$emit("close", false);
            }
          });
        }
      });
    },
    loadCourseAndClassList() {
      //课程信息
      interestCourseApi.edit(this.formModel.interestCourseId)
      .then(response=>{
        if(response.data.result){
          var course = response.data.data.course;

          return interestCourseApi.queryCourseList(course.schoolId,course.termId);
        }
        else{
          return new Promise((resolve,reject)=>{
            resolve(null);
          });
        }
      }).then(response=>{
        if(response!=null && response.data.result){
          this.interestCourseList = response.data.data;
        }
      });
      
      //读取兴趣社团下班级
      interestClassApi.queryClassList(this.formModel.interestCourseId).then(response=>{
        var jsonData = response.data;
        this.loading = false;

        if (jsonData.result) {
          this.interestClassList = jsonData.data;
        }
      });
    }
  },
  created: function() {

  },
  mounted: function () {
    var self = this;

    self.loading = true;

    interestRegisterApi
      .edit(self.businessKey)
      .then((response) => {
        var jsonData = response.data;
        self.loading = false;

        if (jsonData.result) {
          self.formModel = jsonData.data;

          //读取课程信息
          self.loadCourseAndClassList();
        } else {
          self.$message.error(jsonData.message + "");
        }
      })
      .catch((error) => {
        self.$message.error(error + "");
      });
  },
};
</script>